import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2021-12-08-harnett/body-landing"
import SeriousTargets from "./../screens/webinar-replay/serious-targets"

const HarnettView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Improving Antimicrobial Stewardship in Sexually Transmitted Infections"
				videoDisplayTitle="Improving Antimicrobial Stewardship in Sexually Transmitted Infections"
				vimeoLink="https://player.vimeo.com/video/657854104?h=ce52835136"
				placeholderImage="2021-12-08-harnett-webinar-replay.jpg"
				localStorageID="2021-12-08-harnett-webinar-replay"
				pageSlug="/webinar-replay/2021-12-08-harnett/"
			/>
			<BodyLanding pageName="download" localStorageID="2021-12-08-harnett-webinar-replay" />
			<SeriousTargets />
		</React.Fragment>
	)
}

export default HarnettView

export const Head = () => (
  <Seo
        title="Webinar Replay: Improving Antimicrobial Stewardship in STIs"
        description="This webinar covers the 2021 CDC STI guidelines, explores challenges with traditional STI testing and the impact on antibiotic stewardship."
        image="/meta/webinar-harnett-2021-12-08.jpg"
  />
)
