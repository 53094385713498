import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2021_12_08_STI-antimicrobial-stewardship.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

  return (
  <StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">Traditional molecular PCR testing requires a large analyzer in a central laboratory, supplied 
					with reagents or cartridges and operated by skilled lab professionals. For highly accurate and reliable diagnostic results, 
					the healthcare professional has had only two choices:</p>
					<ul data-aos="fade-up">
						<li>Send the collected patient sample to a central/remote PCR lab for batch processing, with turnaround time from hours to days, or</li>
						<li>Invest in point-of-care instrumentation that can bring the test closer, but with instrument bottlenecks, downtime 
						and ongoing expenses for maintenance, calibration and cartridges.</li>
					</ul>
					<p data-aos="fade-up">This problem means patients may not get the treatment or the right treatment - which often leads to unnecessary use of 
					antibiotics and antimicrobial resistance, expedited partner treatment cannot be initiated, and patients are often lost to follow-up. When patients 
					leave without the right treatment, the infection continues to spread in them and possibly to their partner(s) and throughout the community.
					</p>
					<p data-aos="fade-up">This webinar will present the 2021 CDC STI guidelines, dive into the challenges with traditional STI testing and the impact 
					on antibiotic stewardship and review what is needed to combat the growing number of antibiotic resistant STI.
					</p>

					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Review 2021 CDC STI Guidelines</li>
						<li>Examine key challenges with STI testing today at clinical settings such as Urgent Care and Emergency Dept / Hospital including 
						patients lost to follow up, implications of overtreatment / undertreatment of STI</li>
						<li>Evaluate the implications of today’s STI testing to Antibiotic Stewardship.</li>
						<li>Review STI point of care testing to combat the growing number of antibiotic resistant sexually transmitted infections.</li>
					</ul>
				  </div>
				</div>
			
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/glenn-harnett.jpg"
					width={200}
					alt="Dr. Glenn Harnett"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Harnett has over 20 years of experience as a "boots on the ground" clinician, spending 10 years practicing 
				emergency medicine before establishing himself as a nationally respected urgent care physician and key opinion leader. After five years as the 
				Chief Medical Officer for American Family Care while they grew from 17 to 185 clinics, he founded No Resistance Consulting Group in 2016 which 
				focuses on clinical trial site management and recruitment for a growing network of high volume, geographically diverse, urgent care, and 
				multi-specialty trial sites. Dr. Harnett has been an active author and researcher - designing trials, writing trial protocols, and acting as a 
				principal investigator for numerous clinical trials conducted in the urgent care setting. </p>
				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
    />
  )
}

export default FullBody
