import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  width: 100%;
  margin: 40px auto 0;
  padding: 0px;
  overflow: hidden;
  padding-left: 0px;

  ${breakpoint.small`
	padding-left: 20px;
  `}
`

const StyledSeriousTargets = styled.section`
  background-color: ${colors.grayLight};
  position: relative;
  border-bottom-width: 0;
  text-align: center;
  padding: 20px 20px 50px;
  
  ${breakpoint.small`
	text-align: left;
  `}

	${breakpoint.medium`
		padding: 20px;
	`}

  .heading {
	margin: 0px 20px;
	text-align: center;
	padding-bottom: 20px;
	
    ${breakpoint.medium`
      margin: 0px;
      padding-bottom: 60px;
    `}
    
    h2 { 
    	padding-bottom: 40px;
    }

    h3 { 
    	padding: 25px 0 10px;
		font-size: 24px;
		margin-bottom: 3px;
		
		${breakpoint.medium`
			font-size: 30px;
		`}
    }

	.content {
		  display: grid;
		  grid-auto-flow: row;
		  margin: 0 auto;
		  grid-gap: 0;
		  row-gap: 2em;

		  ${breakpoint.small`
		  grid-auto-flow: column;
		  grid-auto-columns: 1fr;
		  grid-gap: 2rem;
		  column-gap: 2.4em;
		  `}
		 }
		  
	.targetImg {
		width: 200px;	
		margin: 0 auto;
	}
}
`


const SeriousTargets = () => {
	return (
		<StyledSeriousTargets>
			<StyledContainer>
				<div className="heading">
					<div className="content">
						<div className="target" data-aos="fade-up">
							<StaticImage
								src="../../../assets/images/sexual-health-test/targets/target1.png"
								width={200}
								alt="Chlamydia"
								className="targetImg"
							/>
							<h3>Chlamydia</h3>
							<p>A common sexually transmitted infection that may not produce symptoms,
								Chlamydia affects people of all ages, and is most common in young women.</p>
						</div>
						<div className="target" data-aos="fade-up">
							<StaticImage
								src="../../../assets/images/sexual-health-test/targets/target3.png"
								width={200}
								alt="Gonorrhea"
								className="targetImg"
							/>
							<h3>Gonorrhea</h3>
							<p>A sexually transmitted bacterial infection that, if untreated, may cause infertility.
								Babies of infected mothers can be infected during childbirth.</p>
						</div>
						<div className="target" data-aos="fade-up">
							<StaticImage
								src="../../../assets/images/sexual-health-test/targets/target2.png"
								width={200}
								alt="Trichomoniasis"
								className="targetImg"
							/>
							<h3>Trichomoniasis</h3>
							<p>Among the most common sexually transmitted infections, Trichomoniasis
								causes a foul-smelling vaginal discharge, genital itching, and painful urination in women.</p>
						</div>
					</div>
				</div>
			</StyledContainer>
		</StyledSeriousTargets>
	)
}

export default SeriousTargets